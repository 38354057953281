@tailwind base;
@tailwind components;
@tailwind utilities;

.shine-box {
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -300px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.15);
    transform: skewX(-30deg);
    transition: 0.3s;
  }
  &:hover:before {
    left: 250px;
    background: rgba(255, 255, 255, 0.2);
  }
}

.tier-SILVER {
  background-color: #b3b6b5 !important;
  color: #fff !important;
}
.tier-GOLD {
  background-color: #f5d100 !important;
  color: #fff !important;
}
.tier-PLATINUM {
  background-color: #444444 !important;
  color: #fff !important;
}
